import { useTranslation } from 'next-i18next';
import { Anchor, LinkStyled } from '@components/common';
import {
  PlusIcon, MagnifyingGlassIcon, SparklesIcon, PaperAirplaneIcon,
} from '@heroicons/react/24/solid';
import { useAppSelector } from '@store/index';

interface IProps {
  isAuthenticated: boolean;
  locale: Locale;
  hasVideoCv?: boolean;
}

/**
 * @description Component that renders the hero section in the homepage for talents
 * @param isAuthenticated - If the user is authenticated
 * @param locale - The app locale
 * @param hasVideoCv - If the user has a video CV
 */
export const HeroHomepage = ({ isAuthenticated, locale, hasVideoCv }: IProps) => {
  const { t } = useTranslation('common');

  const headerInfo = useAppSelector((state) => state.user.headerInfo);
  const isCompleteCv = headerInfo?.resumeScore === 100;

  // Classes for elements
  // ****************************************
  const classes = {
    section: 'flex items-end min-w-full pb-headerOffset relative z-1 min-h-screen sm:pb-0 sm:h-auto lg:items-center lg:min-h-9/10-screen 2xl:short:min-h-9/10-screen 2xl:min-h-3/4-screen',
    content: 'container mx-auto relative z-1 py-6',
    title: 'max-w-xl text-6xl text-dark tracking-tight leading-none mb-2 lg:leading-tight',
    icon: 'w-6 h-6 mr-2',
  };


  // Render component
  // ****************************************
  return (
    <section className={classes.section}>

      <div className={classes.content}>
        <div className="bg-white/50 w-full rounded px-4 py-8 md:w-1/2 lg:px-8 lg:w-1/3">

          {/* title & subtitle */}
          <div className="mb-4 lg:px-4">
            <p className="text-dark text-lg">
              {t('homepage.react.hero.heading.live.subtitle_hour')}
            </p>
          </div>

          {/* buttons */}
          <div className="flex flex-col gap-3">
            {/* auth buttons */}
            {
              isAuthenticated && (
                <>
                  <Anchor
                    href="app_job_canonical"
                    symfonyRoute
                    rounding="full"
                    color="secondary"
                  >
                    <MagnifyingGlassIcon className={classes.icon} />
                    {t('homepage.react.hero.heading.button.all-jobs')}
                  </Anchor>

                  {/* no video cv for user - navigate to video cv page */}
                  {
                    !hasVideoCv && (
                      <LinkStyled href="/resume/new-video-cv" rounding="full" color="light" className="!bg-surface">
                        <PlusIcon className={classes.icon} />
                        {t('homepage.react.hero.heading.button.video_cv')}
                      </LinkStyled>
                    )
                  }
                  {/* if video cv present - navigate to profile */}
                  {
                    hasVideoCv && (
                      <LinkStyled href="/profile" rounding="full" color="light" className="!bg-surface">
                        <PlusIcon className={classes.icon} />
                        {t(!isCompleteCv ? 'homepage.react.hero.heading.button.profile' : 'homepage.react.hero.heading.button.profile_full')}
                      </LinkStyled>
                    )
                  }
                </>
              )
            }

            {/* non-auth buttons */}
            {
              !isAuthenticated && (
                <>
                  <Anchor
                    href="app_job_canonical"
                    symfonyRoute
                    rounding="full"
                    color="secondary"
                  >
                    <MagnifyingGlassIcon className={classes.icon} />
                    {t('homepage.react.hero.heading.button.all-jobs')}
                  </Anchor>
                  <Anchor href="login" symfonyRoute symfonyParams={{ _target_path: encodeURIComponent(`/${locale}/resume/new-video-cv`) }} rounding="full" color="light" className="!bg-surface">
                    <PlusIcon className={classes.icon} />
                    {t('homepage.react.hero.heading.button.video_cv')}
                  </Anchor>
                </>
              )
            }


            <LinkStyled href="premium/talent" rounding="full" color="light" className="!bg-surface">
              <SparklesIcon className={classes.icon} />
              {!isAuthenticated ? t('modal.try.freetrial.button') : t('homepage.react.hero.heading.button.premium_talent')}
            </LinkStyled>
            <Anchor href="coaching" rounding="full" color="light" className="!bg-surface">
              <PaperAirplaneIcon className={classes.icon} />
              {t('homepage.react.hero.heading.button.coaching')}
            </Anchor>
          </div>

        </div>
      </div>

    </section>
  );
};
