import { TagCloudHomeInterface } from '@type/homepage';
import { useTranslation } from 'next-i18next';
import { Anchor } from '@components/common';


// Tag cloud component
// ************************************************************************************
export const TagCloudHome = (props: TagCloudHomeInterface) => {
  // Destructure props
  const { className = '', tagCloud } = props;

  // Translation
  const { t } = useTranslation('common');

  // Return null if no tag cloud
  if (!tagCloud) return null;

  // Render component
  return (
    <div className={className}>
      <div className="container pt-3 pb-10">

        {/* Title */}
        <div className="font-bold mb-7">{t('homepage.tag-cloud.title')}</div>

        {/* Tag cloud */}
        <div className="grid gap-x-14 gap-y-8 md:grid-cols-3">
          {
            tagCloud && tagCloud.map((group) => (
              <div key={group.type} className="grid grid-cols-2 gap-x-14 gap-y-1.5">
                {
                  group.items.map((tag) => (
                    <Anchor href={tag.url} key={tag.title} styling="text" className="!justify-start place-self-start" rounding="none">
                      {tag.title}
                    </Anchor>
                  ))
                }
              </div>
            ))
          }
        </div>

      </div>
    </div>
  );
};
