import { useCallback, useEffect } from 'react';
import { TrackingEvent } from '@type/v1-api-types';

export const useFeedTracking = () => {
  const getStoredEvents = () => {
    const storedEvents = sessionStorage.getItem('trackingData');
    return (storedEvents ? JSON.parse(storedEvents) : []) as TrackingEvent[];
  };

  const trackEvent = useCallback((eventData: TrackingEvent) => {
    const newEvent = eventData;
    const storedEvents = getStoredEvents();
    const updatedEvents = [...storedEvents, newEvent];
    sessionStorage.setItem('trackingData', JSON.stringify(updatedEvents));
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('trackingData');
  }, []);

  const sendTrackingData = useCallback(() => {
    const baseURL = process.env.NEXT_PUBLIC_API_URL as string;
    const storedEvents = getStoredEvents();

    if (storedEvents.length > 0) {
      const shouldSendData = storedEvents.length > 50 || document.visibilityState === 'hidden';

      if (shouldSendData) {
        const payload = JSON.stringify(storedEvents);
        navigator.sendBeacon(`${baseURL}/v2/feed-interactions`, payload);
        sessionStorage.removeItem('trackingData');
      }
    }
  }, []);

  useEffect(() => {
    sendTrackingData();
    document.addEventListener('visibilitychange', sendTrackingData);

    return () => {
      document.removeEventListener('visibilitychange', sendTrackingData);
    };
  }, [sendTrackingData]);

  return { trackEvent };
};
