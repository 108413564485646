import { createContext } from 'react';
import { ExtendedSWRInfiniteResponse } from '@type/swr';

// Context interface
type PickedSwrHookProps<DataType> = Partial<Pick<ExtendedSWRInfiniteResponse<DataType, unknown>, 'showLoadMore' | 'setSize' | 'size' | 'isLoading'>>;
type JobListsContextType<DataType> = PickedSwrHookProps<DataType> & {
  swrJobList?: DataType[],
};

/**
 * @description Context for job lists. Used to store the job list (data from SWR) and the function to load more jobs with SWR.
 * With it, you can use the navigation (previous, next) in Job modal detail.
 *
 * - **swrJobList**: ApiSchema<'JobResult'> - the SWR job list fetched from the server.
 * - **size**: number - the size of the infinite list (get it from the SwrInfinite hook)
 * - **setSize**: Dispatch<SetStateAction<number>> - the function to set the size of the infinite list (get it from the SwrInfinite hook)
 * - **showLoadMore**: boolean - a flag to show the load more button (get it from the SwrInfinite hook).
 * - **isLoading**: boolean - whether the list is loading or not (get it from the SwrInfinite hook).
 */
export const JobListsContext = createContext<JobListsContextType<ApiSchema<'JobResult'>> | undefined>({
  swrJobList: undefined,
  size: undefined,
  setSize: undefined,
  showLoadMore: undefined,
  isLoading: undefined,
});
