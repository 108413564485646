import { useEffect, useCallback } from 'react';

// Type alias for the callback functions that handle keyboard events.
// ******************************************
type ArrowKeyHandler = (event: KeyboardEvent) => void;

// Props interface
// ******************************************
interface ArrowKeysProps {
  onLeft?: ArrowKeyHandler;
  onRight?: ArrowKeyHandler;
  onUp?: ArrowKeyHandler;
  onDown?: ArrowKeyHandler;
}

/**
 * A custom hook that adds event listeners for arrow key presses (up / down / left / right).
 *
 * - **onLeft** - Callback function for the left arrow key.
 * - **onRight** - Callback function for the right arrow key.
 * - **onUp** - Callback function for the up arrow key.
 * - **onDown** - Callback function for the down arrow key.
 *
 * @example
 * const handleLeftArrow = (event: KeyboardEvent): void => {
 *    console.log('Left arrow pressed');
 * };
 *
 * const handleRightArrow = (event: KeyboardEvent): void => {
 *    console.log('Right arrow pressed');
 * };
 *
 * useArrowKeys({
 *  onLeft: handleLeftArrow,
 *  onRight: handleRightArrow,
 * });
 *
 */
export const useArrowKeys = (props: ArrowKeysProps) => {
  // Destructure props
  const {
    onLeft, onRight, onUp, onDown,
  } = props;

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          if (onLeft) {
            onLeft(event);
          }
          break;
        case 'ArrowRight':
          if (onRight) {
            onRight(event);
          }
          break;
        case 'ArrowUp':
          if (onUp) {
            onUp(event);
          }
          break;
        case 'ArrowDown':
          if (onDown) {
            onDown(event);
          }
          break;
        default:
          break;
      }
    },
    [onLeft, onRight, onUp, onDown],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
