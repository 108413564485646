import Head from 'next/head';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { route } from '@services/symfony';
import { useSwrBjFeed } from '@hooks/useSwrApi/endpoints/bj-feed/useSwrBjFeed';
import { gsspFetchData, gsspGetLocale } from '@utils/gssp';
import {
  ApiTagCloudOk,
  ApiBjFeedServer,
} from 'src/utils/data-fetching';

import { EventsFeedList } from '@components/features/events-feed/EventsFeedList';
import { TagCloudHome } from '@components/templates/homepage';
import { SanitizeHTML } from '@components/common/SanitizeHTML/SanitizeHTML';

// Interface
// ************************************************************************************
interface HomepageProps {
  locale: Locale;
  eventsFeedServer?: ApiBjFeedServer;
  tagCloud?: ApiTagCloudOk;
  timestamp: number;
}


// Helper variables
// ************************************************************************************
const eventsFeedUrlParams = {
  limit: 24,
  pageIndex: 1,
};


/**
 * @description Homepage component
 * - **locale** - The locale of the app
 * - **eventsFeedServer** - The events list from GSSP
 * - **tagCloud** - The tag cloud list from GSSP
 * - **timestamp** - The timestamp for the events feed API call (in seconds)
 */
const Index = (props: HomepageProps) => {
  // Destructure props
  const {
    locale,
    eventsFeedServer,
    tagCloud,
    timestamp,
  } = props;


  // Translation
  const { t } = useTranslation('common');

  // The following script tag contains JSON-LD (JavaScript Object Notation for Linked Data)
  // structured data with the application/ld+json MIME type. JSON-LD is used to provide
  // machine-readable information about the website, enhancing its presentation
  // in search engine results and enabling better indexing by search engines.
  const ORG_SCHEMA = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://www.bestjobs.eu/',
    name: 'BestJobs',
    // SiteLinks Search Box
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `https://www.bestjobs.eu${route('app_job_canonical')}/{search_term_string}?utm_source=google&utm_medium=organic%20search`,
      },
      'query-input': 'required name=search_term_string',
    },
  });


  // SWR - EVENTS FEED
  // ******************************
  const {
    data,
    size,
    sizeAsRef,
    setSize,
    isLoading,
    isEmpty,
    showLoadMore,
  } = useSwrBjFeed({
    locale,
    urlParams: `&showEventsBefore=${timestamp}`,
    limit: eventsFeedUrlParams.limit,
    startIndex: eventsFeedUrlParams.pageIndex,
    serverSideData: eventsFeedServer,
  });

  // Render page
  // ******************************
  return (
    <>
      {/* HEAD - title, meta, etc */}
      <Head>
        <title>
          {t('homepage.react.meta.title')}
        </title>
        <meta name="description" content={t('meta.homepage.description_react')} />
        <SanitizeHTML as="script" type="application/ld+json" htmlContent={ORG_SCHEMA} />
      </Head>


      {/* PAGE CONTENT */}
      <div className="grow bg-surface-100">


        {/* Events feed */}
        <div className="container">
          <EventsFeedList
            data={data}
            size={size}
            setSize={setSize}
            sizeAsRef={sizeAsRef}
            isLoading={isLoading}
            isEmpty={isEmpty}
            showLoadMore={showLoadMore}
            locale={locale}
          />
        </div>

        {/* Tag cloud */
          !!tagCloud && <TagCloudHome tagCloud={tagCloud} className="relative bg-surface-100" />
        }

      </div>
    </>
  );
};


/**
 * @description Get the server-side props
 * @param context - The context object
 */
export const getServerSideProps: GetServerSideProps = async (context) => {
  // Props destructuring
  const {
    params,
    req,
  } = context;

  const locale = gsspGetLocale(params);

  // Get the timestamp in seconds (for PHP). Needed as a parameter for the bjFeed API call.
  const timestamp = Math.floor(Date.now() / 1000);


  // Fetch data
  // We're using the common url params for the bjFeed, so we can sync with the SWR hook.
  const { limit, pageIndex } = eventsFeedUrlParams;
  const endpoints = [`/v2/bj-feed?limit=${limit}&page=${pageIndex}&showEventsBefore=${timestamp}`, '/v1/tag-cloud'];
  const [eventsFeedServer, tagCloud] = await gsspFetchData({ endpoints, locale, req });

  // Return the props
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      locale,
      eventsFeedServer,
      tagCloud,
      timestamp,
    },
  };
};


/**
 * @description Export the page component.
 */
export default Index;

