import { AnchorProps, LinkStyledProps } from '@type/anchors-buttons';
import { ApiBjFeedItem, ApiBjFeedItemNice } from '@type/v1-api-types/bj-feed';
import { TFunction } from 'next-i18next';


// Interfaces
// *****************************************
type ObjAnchorProps = Pick<AnchorProps, 'styling' | 'href' | 'symfonyParams' | 'symfonyRoute' | 'className'>;
type ObjLinkStyledProps = Pick<LinkStyledProps, 'styling' | 'href' | 'className' | 'prefetch'>;

interface GetMainLinkProps {
  <B extends boolean>(isJobPost: B, mainLinkSlug: string, absoluteClass?: boolean): B extends true ? ObjAnchorProps : ObjLinkStyledProps;
}


/**
 * @description Get the main link props for the event card as an object to be spread in the component.
 * @param isJobPost - Boolean indicating if the event is a job post
 * @param mainLinkSlug - Slug of the main link. If it's a job post, it's the job slug, otherwise, it's the candidate slug
 * @param absoluteClass - Boolean indicating if the link should have an absolute class (for card body) or not (for card main image)
 *
 * Returns:
 * - **styling** - 'none': The styling of the link
 * - **className** - The classes for the link
 * - **href** - The href of the link
 * - **symfonyRoute** - Boolean indicating if the link is a symfony route
 * - **symfonyParams** - The parameters for the symfony route
 * - **prefetch** - false: Boolean indicating if the link should be prefetched
 */
export const getMainLinkProps: GetMainLinkProps = <B extends boolean>(isJobPost: B, mainLinkSlug: string, absoluteClass = false) => {
  const commonProps = {
    styling: 'none',
    className: absoluteClass ? 'absolute inset-0' : 'flex aspect-square justify-center items-center relative',
    href: isJobPost ? 'app_job_detail' : `/cv/${mainLinkSlug}`,
    target: '_blank',
    prefetch: isJobPost ? 'true' : undefined,
  } as ObjLinkStyledProps;

  if (isJobPost) {
    return {
      ...commonProps,
      symfonyRoute: true,
      symfonyParams: { slug: mainLinkSlug },
    };
  }

  return commonProps;
};


/**
 * @description Build a usable event object by replacing the eventParams array with an object where the keys are the name
 * of the eventParams and the values are the value of the eventParams
 * @param event - Event object
 */
const buildEventNiceObject = (event: ApiBjFeedItem): ApiBjFeedItemNice => {
  const eventParams = Object.entries(event.eventData);
  const niceData = eventParams[0][1];
  return { ...event, eventData: niceData };
};


/**
 * @description Build the event data object that will be used in the CardEvent component. It makes all the necessary
 * translations and returns the data object for the event.
 *
 * Parameters:
 * - **event** - Event object
 * - **t** - The translation function
 *
 * Returns:
 * - **preTitle** - The pre-title of the event (Candidate name or 'Company X has posted a job')
 * - **title** - The title of the event (Job title or 'Candidate X ...')
 * - **isJobPost** - Boolean indicating if the event is a job post
 * - **jobTitle** - The job title
 * - **jobSlug** - The job slug
 * - **imgCompany** - The company logo server object URL
 * - **candidateSlug** - The candidate slug
 * - **imgCandidate** - The candidate image server object URL
 * - **isCvDetailAvailable** - Boolean that indicates if we can show in modal the cv detail
 * - **location** - The location of the job for company-hire
 * - **rating** - The rating for company-review
 * - **avgRating** - The average rating for company-review (card footer)
 * - **salary** - The salary for salary-add
 * - **avgSalary** - The suggested salary range for salary-add (card footer)
 * - **mainName** - The main name of the talent/company (Company name or Candidate name, to be used for avatars)
 * - **mainLinkSlug** - The slug of the main link (Job slug or Candidate slug)
 * - **hasFooterLink** - Boolean indicating if the event has a footer link
 * - **footerLink** - The footer link object
 *
 * Footer link object:
 * - **type** - The type of the link (company or search)
 * - **href** - The href of the link
 * - **urlParams** - The parameters for the link
 * - **linkTitle** - The title of the link (Company name or Job title)
 * - **linkSubtitle** - The subtitle of the link
 */
export const buildEventData = (event: ApiBjFeedItem, t: TFunction, locale: Locale) => {
  // Build a usable event object and destructure it
  const eventNice = buildEventNiceObject(event);
  const { eventType, eventData } = eventNice;

  // Variables
  // ********************************************
  const isJobPost = (eventType === 'company-hire') || (eventType === 'application-viewed');
  const isCompanyReviewPost = eventType === 'company-review';
  const isCvDetailAvailable = (eventType === 'candidate-hire' || eventType === 'company-review' || eventType === 'candidate-apply' || 'salary-add');
  const isTalentPost = (eventType !== 'company-hire') && (eventType !== 'application-viewed');
  const titleSalary = eventData?.candidateSuggestedSalaryRange || eventData?.candidateSalary;
  const hasFooterLink = eventData?.companySlug || titleSalary;
  const companySlug = eventData?.companySlug;
  const companyName = eventData?.companyName;
  const workplaceSlug = eventData?.workplaceSlug;

  // Footer link object; URL params are only for Anchor links (to symfony routes)
  const footerLink = {
    href: workplaceSlug ? `/company-profile/${workplaceSlug}` : '/campaigns/cv-worth',
    linkTitle: eventData?.workplaceName,
    linkSubtitle: t('label.see.company.jobs'),
  };

  // Multi-value variables
  let preTitle = eventData?.candidateName;

  const translation = Array.isArray(eventData?.jobTranslate) && eventData?.jobTranslate.length > 0
    ? eventData?.jobTranslate.find(
      (tr) => tr.locale === locale && tr.field === 'title',
    )
    : null;

  let title = translation?.content?.trim() ? translation.content : eventData?.jobTitle;


  // Handle the data based on the event type
  // ********************************************
  switch (eventType) {
    case 'company-hire':
      preTitle = t('bjEvent.company-hire.pre-title', { companyName: eventData?.workplaceName });
      break;
    case 'candidate-hire':
      preTitle = t('bjEvent.candidate-hire.title', { candidateName: eventData?.candidateName });
      break;
    case 'company-review':
      preTitle = t('bjEvent.company-review.title.variable', { candidateName: eventData?.candidateName });
      title = eventData?.companyName;
      footerLink.linkTitle = eventData?.candidateName;
      footerLink.href = `/cv/${eventData?.candidateSlug}`;
      break;
    case 'salary-add':
      preTitle = t('bjEvent.salary-add.pre-title', { candidateName: eventData?.candidateName });
      title = `${titleSalary} ${t('job.detail.net_salary_suffix')}`;
      footerLink.linkTitle = t('bjEvent.cv-worth.link.title');
      footerLink.linkSubtitle = t('bjEvent.cv-worth.link.subtitle');
      break;
    case 'candidate-apply':
      preTitle = t('bjEvent.candidate-apply.pre-title');
      title = eventData?.candidateName;
      footerLink.href = `/job/${eventData?.jobSlug}/applicants`;
      footerLink.linkTitle = eventData?.jobTitle;
      footerLink.linkSubtitle = t('global.label.see.all.applicants');
      break;

    case 'application-viewed':
      footerLink.href = workplaceSlug ? `/company-profile/${workplaceSlug}` : `/job/${eventData?.jobSlug}`;
      footerLink.linkTitle = eventData?.jobTitle;
      preTitle = t('bjEvent.application-viewed.pre-title', { companyName: eventData?.companyName });
      break;
    default:
  }


  const mainLinkSlug = (isJobPost && eventData?.jobSlug)
    || (isTalentPost && eventData?.jobSlug)
    || eventData?.candidateSlug;


  // Return the event data
  // ********************************************
  return {
    preTitle,
    title,
    isJobPost,
    isCvDetailAvailable,
    isTalentPost,
    jobTitle: eventData?.jobTitle,
    jobSlug: eventData?.jobSlug,
    imgCompany: eventData?.companyLogoName,
    imgCandidate: eventData?.candidateImageName ?? eventData?.candidateImageUrl,
    candidateSlug: eventData?.candidateSlug,
    likedProp: eventData?.likedProp || false,
    jobApplied: eventData?.jobApplied || false,
    location: eventData?.jobLocation,
    rating: eventData?.companyRating,
    avgRating: eventData?.companyAvgRating,
    salary: eventData?.candidateSalary,
    avgSalary: eventData?.candidateSuggestedSalaryRange,
    mainName: isJobPost || isCompanyReviewPost ? eventData?.companyName : eventData?.candidateName,
    mainLinkSlug,
    hasFooterLink,
    footerLink,
    companySlug,
    companyName,
    imgJob: eventData?.jobImageName,
    highLight: eventData?.companyHighlight || false,
    workplaceSlug,
    imgWorkplace: eventData?.workplaceLogoName,
  };
};
