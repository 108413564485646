import { useTranslation } from 'next-i18next';
import { CardJobTalent } from '@components/templates/job/Card/CardJobTalent';
import { LinkStyled } from '@components/common';

// Interface
// *******************************************************************
export interface JobListHomepageInterface {
  locale: Locale,
  jobListCards?: ApiSchema<'JobResult'>,
}


// Job list cards component
// *******************************************************************
export const JobListCardsHome = (props: JobListHomepageInterface) => {
  // Destructure props
  const { locale, jobListCards } = props;

  // Translation
  const { t } = useTranslation('common');

  return (
    <div className="bg-surface-100">
      <div className="container">
        { jobListCards && <div className="py-5 font-bold">{t('job-list.title.number', { total: jobListCards.totalVacancies })}</div>}

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-6">
          { jobListCards?.items && jobListCards.items.map((job) => (<CardJobTalent key={job.id} job={job} locale={locale} />)) }
        </div>

        {/* View all jobs button */}
        <div className="py-6 text-center">
          <LinkStyled href="/job/list" styling="outline" rounding="full" className="bg-surface">
            {t('home.btn.label.see-jobs')}
          </LinkStyled>
        </div>

      </div>
    </div>
  );
};
