import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'next-i18next';
import { ApiBjFeedItem } from '@type/v1-api-types/bj-feed';
import {
  Anchor, Avatar, Card, LinkStyled, Modal, RatingScore,
} from '@components/common';
import { ChevronRightIcon, ChartBarIcon } from '@heroicons/react/16/solid';
import { clsx } from 'clsx';
import { JobDetailHub } from '@components/templates/job/JobDetailHub';
import { CvDetailHub } from '@components/templates/cv/CvDetailHub';
import { useHeaderInfoData } from '@components/layout/Header/MainNavbar/data/useHeaderInfoData';
import { CardActionsForUser } from '@components/features/events-feed/actions/CardActionsForUser';
import { CardActionsForEmployer } from '@components/features/events-feed/actions/CardActionsForEmployer';
import { useModalHistory } from '@hooks/history/useModalHistory';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import { useFeedTracking } from '@hooks/tracking/useFeedTracking';
import { useRouter } from 'next/router';
import { useFeedCardIsInViewport } from '@hooks/tracking/useFeedCardIsInViewport';
import { getI18nLinkHref } from '@services/i18nPaths';
import { dataTestId } from '@services/data-test-id';
import { buildEventData, getMainLinkProps } from './_eventFunctions';

// Interface
// *****************************************
interface EventsFeedCardProps {
  event: ApiBjFeedItem;
  isCompany: boolean;
  locale: Locale;
  itemPosition: number;
  requestId: string;
}


/**
 * @description Card component for displaying events.
 *
 * - **event:** The event object containing the event type and parameters.
 */
export const EventsFeedCard = (props: EventsFeedCardProps) => {
  // Destructure props
  const {
    event, isCompany, locale, itemPosition, requestId,
  } = props;

  const { trackEvent } = useFeedTracking();

  // Translation
  const { t } = useTranslation('common');

  const { headerInfoData } = useHeaderInfoData(locale);
  const router = useRouter();

  const showFooterImage = event.eventType !== 'candidate-apply';

  const [mountTime, setMountTime] = useState<number>(0);

  useEffect(() => {
    const startTime = Date.now();
    setMountTime(startTime);
  }, []);

  // Process the event data
  // *****************************************
  const processedData = buildEventData(event, t, locale);
  const {
    isJobPost,
    isTalentPost,
    mainName,
    imgCompany,
    imgCandidate,
    imgJob,
    mainLinkSlug,
    preTitle,
    title,
    jobTitle,
    jobSlug,
    avgRating,
    hasFooterLink,
    footerLink,
    candidateSlug,
    isCvDetailAvailable,
    jobApplied = false,
    workplaceSlug,
    imgWorkplace,
  } = processedData;

  // Helper components
  // *****************************************
  const avatarImgFolder = useMemo(
    () => {
      if (isJobPost) {
        return imgJob ? 'feed_image' : 'employer_logo';
      }
      if (event.eventType === 'company-review') {
        return 'employer_logo';
      }
      return 'resume_image';
    },
    [isJobPost, imgJob, event.eventType],
  );

  const avatarImgPath = (() => {
    if (isJobPost) {
      return imgJob ?? imgCompany;
    }
    if (event.eventType === 'company-review') {
      return imgWorkplace;
    }
    return imgCandidate;
  })();

  const getAvatarAspectRatio = () => {
    if (event.eventType === 'company-review') {
      return 'landscape';
    }
    if (isJobPost && !imgJob) {
      return 'square-fit';
    }
    return 'square-fill';
  };


  const avatar = (
    <Avatar
      size={avatarImgPath ? '3xl' : '2xl'}
      name={mainName}
      alt={mainName}
      imgPath={avatarImgPath}
      aspectRatio={getAvatarAspectRatio()}
      imgFolder={avatarImgFolder}
      id={(mainName || '').charCodeAt(0)}
      className={clsx('max-h-full w-full max-w-full', { 'w-auto': isJobPost && !imgJob })}
      rounding="base"
    />
  );


  // Hidden text Link on card body
  const linkHiddenText = (
    <span className="opacity-0">{title}</span>
  );

  // Footer link
  // *****************************************
  // Footer link image - Logo or Icon
  let footerLinkImage;

  switch (event.eventType) {
    case 'salary-add':
      footerLinkImage = (
        <span className="flex size-10 shrink-0 items-center justify-center rounded-md bg-secondary/10">
          <ChartBarIcon className="size-5 text-secondary" />
        </span>
      );
      break;

    case 'company-review':
      footerLinkImage = (
        <Avatar
          className="shrink-0"
          aspectRatio="landscape"
          size="sm"
          rounding="base"
          name={footerLink?.linkTitle}
          id={(mainName || '').charCodeAt(0)}
          imgPath={imgCandidate}
          imgFolder="resume_image"
        />
      );
      break;

    case 'application-viewed':
      footerLinkImage = (
        <Avatar
          className="shrink-0"
          aspectRatio="landscape"
          size="sm"
          rounding="base"
          name={footerLink?.linkTitle}
          id={(mainName || '').charCodeAt(0)}
          imgPath={imgCandidate}
          imgFolder="resume_image"
        />
      );
      break;

    default:
      footerLinkImage = (
        <Avatar
          className="shrink-0"
          aspectRatio="landscape"
          size="sm"
          rounding="base"
          name={footerLink?.linkTitle}
          id={(mainName || '').charCodeAt(0)}
          imgPath={imgWorkplace}
          imgFolder="employer_logo"
        />
      );
      break;
  }

  // Footer link text
  const footerLinkText = (
    <span className="grow truncate text-sm leading-snug">
      <span className="block truncate font-bold">{footerLink?.linkTitle}</span>
      {/* Rating score for company-review, subtitle for the rest (if link) */}
      {!avgRating && hasFooterLink && <span className="truncate text-ink-medium">{footerLink?.linkSubtitle}</span>}
    </span>
  );


  // Footer link content; don't show the logo for candidate-apply
  const footerLinkContent = (
    <>
      <div className="flex items-center gap-2">
        {showFooterImage && footerLinkImage}
        <span className="w-full overflow-hidden duration-300 md:w-fit md:group-hover:w-0">
          {footerLinkText}
        </span>
      </div>

      {hasFooterLink && <ChevronRightIcon className="size-5 shrink-0 text-ink-medium" />}
    </>
  );


  // Classes
  // *****************************************
  const salaryContainer = 'inline-block text-sm text-ink-medium border border-dashed border-input/70 px-2 pt-1 pb-0.5 my-1';
  const footerContentContainer = 'flex items-center justify-between overflow-hidden md:group-hover:justify-end min-h-10';
  const cardClasses = 'group/talentCard group relative overflow-hidden rounded-md bg-surface shadow-xs';

  const [openJobDetailModal, setOpenJobDetailModal] = useState(false);
  const [openCvDetailModal, setOpenCvDetailModal] = useState(false);
  const { updateModalHistory } = useModalHistory();
  const isAuthenticated = !!headerInfoData;

  const { ref } = useFeedCardIsInViewport({
    itemPosition,
    mountTime,
    requestId,
    slug: headerInfoData?.userSlug || '',
    eventId: event.id,
    eventType: event.eventType,
  });

  const logEventAndSendTag = useCallback(
    (interactionType: string, tagEvent?: string) => {
      const currentTime = Date.now();
      const durationInSeconds = currentTime - mountTime;

      trackEvent({
        position: itemPosition,
        event_id: event.id,
        request_id: requestId,
        event_type: event?.eventType as string,
        interaction: interactionType,
        created_at: new Date().toISOString(),
        duration: durationInSeconds,
        context: 'Context',
        userSlug: headerInfoData ? headerInfoData?.userSlug as string : '',
      });

      if (tagEvent) { sendGoogleTagEvent(tagEvent, 'Custom'); }
    },
    [trackEvent, itemPosition, event.id, requestId, event.eventType, mountTime, headerInfoData],
  );

  const renderModal = (isOpen: boolean, onClose: () => void, body: React.ReactNode) => (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      beforeLeave={() => updateModalHistory({ pathname: '/' })}
      size="full"
      className="!transform-none !overflow-visible"
      body={body}
    />
  );

  // Render component
  // *****************************************
  return (
    <Card animation="rise" ref={ref} className={cardClasses}>

      {renderModal(
        openJobDetailModal,
        () => setOpenJobDetailModal(false), (
          <JobDetailHub jobSlug={jobSlug} locale={locale} closeModal={() => setOpenJobDetailModal(false)} />
        ),
      )}

      {renderModal(
        openCvDetailModal,
        () => setOpenCvDetailModal(false), (
          <CvDetailHub slug={candidateSlug} isAuthenticated={isAuthenticated} closeModal={() => setOpenCvDetailModal(false)} />
        ),
      )}

      {/* DESCRIPTION and IMAGE */}
      <Card.Body className="relative mx-6 border-b !px-0 !pb-4 pt-6">
        <div className="flex min-h-80 w-full items-center justify-center pb-4">
          {avatar}
        </div>

        {mainLinkSlug && (() => {
          if (isJobPost || isCvDetailAvailable) {
            if (event.eventType === 'application-viewed') {
              return (
                <Anchor
                  styling="none"
                  {...getMainLinkProps(isJobPost, mainLinkSlug, true)}
                  onClick={(e) => {
                    e.preventDefault();
                    if (isJobPost) {
                      logEventAndSendTag('job_detail', 'feed_action_card_show_job_detail');
                      setOpenJobDetailModal(true);
                      updateModalHistory({
                        pathname: `${getI18nLinkHref(`/job/${jobSlug}`, locale)}`,
                      });
                    }
                  }}
                >
                  {linkHiddenText}
                </Anchor>
              );
              return null;
            }

            if (event.eventType === 'company-review') {
              if (workplaceSlug) {
                return (
                  <Anchor
                    styling="none"
                    {...getMainLinkProps(isJobPost, mainLinkSlug, true)}
                    onClick={(e) => {
                      e.preventDefault();
                      logEventAndSendTag('employer_info');
                      void router.push(`/company-profile/${workplaceSlug}`);
                    }}
                  >
                    {linkHiddenText}
                  </Anchor>

                );
              }
              return null;
            }
            return (
              <Anchor
                styling="none"
                {...getMainLinkProps(isJobPost, mainLinkSlug, true)}
                onClick={(e) => {
                  e.preventDefault();
                  if (isJobPost) {
                    logEventAndSendTag('job_detail', 'feed_action_card_show_job_detail');
                    setOpenJobDetailModal(true);
                    updateModalHistory({
                      pathname: `${getI18nLinkHref(`/job/${jobSlug}`, locale)}`,
                    });
                  } else {
                    setOpenCvDetailModal(true);
                    logEventAndSendTag('cv_detail', 'feed_action_card_show_cvdetail');
                    updateModalHistory({
                      pathname: `/cv/${mainLinkSlug}`,
                    });
                  }
                }}
              >
                {linkHiddenText}
              </Anchor>
            );
          }
          return (
            <LinkStyled {...getMainLinkProps(isJobPost, mainLinkSlug, true)}>
              {linkHiddenText}
            </LinkStyled>
          );
        })()}


        {/* Content for all event types */}
        <div className="flex items-center gap-4">
          <div className="min-w-0 grow break-words">
            {/* Pre-title - all events */
                preTitle && <div className="text-sm text-ink-medium">{preTitle}</div>
            }

            {/* Title - special for salary-add (cv-worth) */
                title && (
                  event.eventType === 'salary-add'
                    ? (
                      <div className={salaryContainer}>
                        {t('bjEvent.cv-worth.salary.pre-title', { jobTitle })}
                        <span className="block font-bold">{title}</span>
                      </div>
                    ) : (
                      <h2 className="my-1.5 text-lg font-bold leading-tight">{title}</h2>
                    )
                )
            }

            {avgRating && <RatingScore score={Number(avgRating)} showScore className="size-4 text-warning" />}
          </div>

          {/* Arrow icon if we have a link */
                mainLinkSlug && <ChevronRightIcon className="size-5 shrink-0 text-ink-medium" />
            }
        </div>
      </Card.Body>


      {/* FOOTER, includes Actions slide from left side, and company or cv detail or simple button */}

      <div className="flex flex-col-reverse items-center md:flex-row">
        <Card.Actions className="mt-1 w-full flex-1 -translate-x-0 py-4 opacity-100 transition-transform duration-300 hover:translate-x-0 md:w-0 md:-translate-x-full md:py-0 md:opacity-0 md:group-hover:w-fit md:group-hover:translate-x-0 md:group-hover:opacity-100">
          {isTalentPost ? (
            <CardActionsForUser
              candidateSlug={mainLinkSlug ?? ''}
              candidateName={mainName ?? ''}
              locale={locale}
              isCompany={isCompany}
              isAuthenticated={isAuthenticated}
              isReviewCard={event.eventType === 'company-review'}
              eventType={event.eventType}
              itemPosition={itemPosition}
              eventId={event.id}
              requestId={requestId}
              userSlug={headerInfoData?.userSlug ? headerInfoData.userSlug : ''}
            />
          ) : (
            <CardActionsForEmployer
              isCompany={isCompany}
              isAuthenticated={isAuthenticated}
              locale={locale}
              jobApplied={jobApplied}
              jobSlug={mainLinkSlug ?? ''}
              eventType={event.eventType}
              itemPosition={itemPosition}
              eventId={event.id}
              requestId={requestId}
              userSlug={headerInfoData?.userSlug ? headerInfoData.userSlug : ''}
            />
          )}

        </Card.Actions>

        <Card.Footer
          className="w-full border-b px-6 py-5 md:border-b-0 md:group-hover:w-fit md:group-hover:pl-0"
        >
          {hasFooterLink ? (
            (() => {
              if (event.eventType === 'salary-add' || event.eventType === 'candidate-apply') {
                return (
                  <LinkStyled
                    href={footerLink.href}
                    target="_blank"
                    styling="none"
                    className={footerContentContainer}
                  >
                    {footerLinkContent}
                  </LinkStyled>
                );
              } if (event.eventType === 'company-review') {
                return (
                  <LinkStyled
                    href={footerLink.href}
                    target="_blank"
                    styling="none"
                    className={footerContentContainer}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenCvDetailModal(true);
                      logEventAndSendTag('cv_detail', 'feed_action_card_show_cv_detail');
                      updateModalHistory({
                        pathname: `/cv/${mainLinkSlug}`,
                      });
                    }}
                    {...dataTestId('eventsFeedCard.footer.viewCv')}
                  >
                    {footerLinkContent}
                  </LinkStyled>
                );
              }
              return (
                <LinkStyled
                  href={footerLink.href}
                  target="_blank"
                  styling="none"
                  className={footerContentContainer}
                  onClick={(e) => {
                    e.preventDefault();
                    logEventAndSendTag((footerLink.href === 'app_company_detail' ? 'employer_info' : event?.eventType as string));
                    void router.push(footerLink.href);
                  }}
                  {...dataTestId('eventsFeedCard.footer.companyProfile')}
                >
                  {footerLinkContent}
                </LinkStyled>
              );
            })()
          ) : (
            /* No link case */
            <div className={footerContentContainer}>{footerLinkContent}</div>
          )}
        </Card.Footer>


      </div>

    </Card>
  );
};


/**
 * @description Memoized CardEvent component.
 */
export const EventsFeedCardMemo = memo(EventsFeedCard);
