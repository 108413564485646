// Props interface
// ******************************************
import {
  useRef, useState, RefObject, useEffect, useCallback,
} from 'react';

// Props interface
// ******************************************
interface SwipeNavProps {
  swipeContainer: RefObject<HTMLElement>;
  onPrev?: () => void;
  onNext?: () => void;
}

/**
 * A custom hook for handling swipe navigation. To be used in job modal.
 * Note that this is not actually a slider, it only handles swipe events. Use the callbacks to set the active item.
 *
 * - **swipeContainer** - RefObject<HTMLElement> - The container element to attach swipe listeners to.
 * - **onPrev** - Callback function to call on swipe to the previous item..
 * - **onNext** - Callback function to call on swipe to the next item.
 *
 * Returns:
 * - **moveX** - number - The current horizontal movement value. To be added inline to the container element.
 *
 * @example
 * const SwipeNavExample = () => {
 *   const swipeContainerRef = useRef(null);
 *   const [currentIndex, setCurrentIndex] = useState(0);
 *   const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];
 *
 *   const handlePrev = () => {
 *     setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : items.length - 1));
 *   };
 *
 *   const handleNext = () => {
 *     setCurrentIndex(prevIndex => (prevIndex < items.length - 1 ? prevIndex + 1 : 0));
 *   };
 *
 *   const { moveX } = useSwipeNav({
 *     swipeContainer: swipeContainerRef,
 *     onPrev: handlePrev,
 *     onNext: handleNext,
 *   });
 *
 *   return (
 *     <div ref={swipeContainerRef} style={{ overflow: 'hidden', width: '300px', border: '1px solid black' }}>
 *       <div
 *         style={{
 *           display: 'flex',
 *           transform: `translateX(${moveX}px)`,
 *           transition: moveX === 0 ? 'transform 0.3s ease' : 'none',
 *         }}
 *       >
 *         {items.map((item, index) => (
 *           <div
 *             key={index}
 *             style={{
 *               minWidth: '300px',
 *               height: '200px',
 *               display: 'flex',
 *               alignItems: 'center',
 *               justifyContent: 'center',
 *               border: '1px solid gray',
 *             }}
 *           >
 *             {item}
 *           </div>
 *         ))}
 *       </div>
 *     </div>
 *   );
 * };
 *
 */
export const useSwipeNav = (props: SwipeNavProps) => {
  // Destructure props
  const {
    swipeContainer, onPrev, onNext,
  } = props;

  // Initialize state and refs
  const xStart = useRef(0);
  const yStart = useRef(0);
  const deltaX = useRef(0);
  const deltaY = useRef(0);

  const dragStartThreshold = 20;
  const dragMaxY = 100;
  const dragTriggerThreshold = 70;

  const [moveX, setMoveX] = useState<number | undefined>(0);

  // Handle touch start event
  const handleTouchStart = useCallback((e: TouchEvent) => {
    xStart.current = e.changedTouches[0].clientX;
    yStart.current = e.changedTouches[0].clientY;
  }, []);

  // Handle touch move event
  const handleTouchMove = useCallback((e: TouchEvent) => {
    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;

    deltaX.current = Math.round(currentX - xStart.current);
    deltaY.current = Math.round(currentY - yStart.current);

    const allowXDrag = Math.abs(deltaX.current) > dragStartThreshold && Math.abs(deltaY.current) < dragMaxY;

    if (allowXDrag) {
      setMoveX(deltaX.current - dragStartThreshold);
    }
  }, []);

  // Handle touch end event
  const handleTouchEnd = useCallback(() => {
    const notVerticalSwipe = Math.abs(deltaY.current) < dragMaxY;
    const allowPrev = deltaX.current > dragTriggerThreshold && notVerticalSwipe;
    const allowNext = deltaX.current < -dragTriggerThreshold && notVerticalSwipe;

    if (allowPrev) {
      if (onPrev) {
        setMoveX(window.innerWidth);

        setTimeout(() => {
          onPrev();
          setTimeout(() => {
            setMoveX(undefined);
          }, 1);
        }, 300);
      }
    } else if (allowNext) {
      if (onNext) {
        setMoveX(-window.innerWidth);

        setTimeout(() => {
          onNext();
          setTimeout(() => {
            setMoveX(undefined);
          }, 1);
        }, 300);
      }
    } else {
      setMoveX(0);
    }

    // Reset variables
    xStart.current = 0;
    yStart.current = 0;
    deltaX.current = 0;
    deltaY.current = 0;
  }, [onPrev, onNext]);

  // Effect to add and remove event listeners
  // ******************************************
  useEffect(() => {
    const container = swipeContainer.current;

    if (container) {
      container.addEventListener('touchstart', handleTouchStart);
      container.addEventListener('touchmove', handleTouchMove);
      container.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (container) {
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchmove', handleTouchMove);
        container.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [swipeContainer, handleTouchStart, handleTouchMove, handleTouchEnd]);

  // Return
  // ******************************************
  return {
    moveX,
  };
};
