import {
  forwardRef, useCallback, useEffect, useState,
} from 'react';
import { CardActionElementBaseProps, CardRef } from '@type/card';
import { useJobTalentActions } from '@hooks/features/useJobTalentActions';
import { Button, LinkStyled, Modal } from '@components/common';
import { useTranslation } from 'next-i18next';
import { JobDetailHub } from '@components/templates/job/JobDetailHub';
import { useModalHistory } from '@hooks/history/useModalHistory';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import { useFeedTracking } from '@hooks/tracking/useFeedTracking';
import { JobPlacement } from '@type/job-params';
import { getI18nLinkHref } from '@services/i18nPaths';


// ***************************************************************
export const CardActionsForEmployer = forwardRef<CardRef, CardActionElementBaseProps>((props, ref) => {
  // destructure props
  const {
    jobSlug,
    jobId,
    jobTitle,
    isCompany,
    jobApplied = false,
    locale = 'ro',
    isAuthenticated,
    eventType,
    eventId,
    requestId,
    itemPosition,
    userSlug,
  } = props;

  const { t } = useTranslation('common');

  const { trackEvent } = useFeedTracking();

  const [mountTime, setMountTime] = useState<number>(0);

  useEffect(() => {
    const startTime = Date.now();
    setMountTime(startTime);
  }, []);


  const {
    isApplying,
    applyToJob,
  } = useJobTalentActions({
    jobSlug: jobSlug || '',
    jobId: jobId || '',
    jobTitle,
    appliedProp: !!jobApplied,
    withEasyApply: !!isAuthenticated,
    placement: JobPlacement.FEED,
  });

  const { updateModalHistory } = useModalHistory();

  const [openJobDetailModal, setOpenJobDetailModal] = useState(false);

  const logEventAndSendTag = useCallback(
    (interactionType: string, tagEvent: string) => {
      const currentTime = Date.now();
      const durationInSeconds = currentTime - mountTime;

      trackEvent({
        position: itemPosition,
        event_id: eventId,
        request_id: requestId,
        event_type: eventType,
        interaction: interactionType,
        created_at: new Date().toISOString(),
        duration: durationInSeconds,
        context: 'Context',
        userSlug,
      });

      sendGoogleTagEvent(tagEvent, 'Custom');
    },
    [trackEvent, itemPosition, eventId, requestId, eventType, mountTime, userSlug],
  );

  const renderActionButton = () => {
    if (eventType === 'application-viewed') {
      return (
        <LinkStyled
          href="/applied-jobs"
          target="_blank"
          styling="none"
          className="block min-w-full rounded-full bg-secondary bg-opacity-10 px-4.5 py-2.5 text-center text-sm font-medium text-secondary hover:bg-opacity-100 hover:text-surface"
        >
          {t('header.menu.applied_jobs')}
        </LinkStyled>
      );
    }


    return (
      <Button
        styling="soft"
        color="secondary-light"
        rounding="full"
        size="sm"
        className="min-w-full overflow-hidden"
        isLoading={isApplying}
        spinnerClass="w-5 h-5 -ml-1 mr-2"
        data-test-id={isCompany ? 'feedCard-footer-jobDetails' : 'feedCard-footer-jobApply'}
        onClick={() => {
          if (isCompany) {
            setOpenJobDetailModal(true);
            updateModalHistory({
              pathname: `/job/${jobSlug}`,
            });
            logEventAndSendTag('job_detail', 'feed_action_job_open_job_from_card');
          } else {
            logEventAndSendTag('job_apply', 'feed_action_job_apply_from_card');
            applyToJob();
          }
        }}
      >
        <span className="truncate">
          { t('job.apply') }
        </span>
      </Button>
    );
  };


  return (
    <div className="mx-6 space-x-1.5" ref={ref}>
      <Modal
        isOpen={openJobDetailModal}
        onClose={() => setOpenJobDetailModal(false)}
        beforeLeave={() => updateModalHistory({
          pathname: '/',
        })}
        size="full"
        containerClasses="overflow-x-hidden"
        className="!transform-none !overflow-visible"
        body={(
          <JobDetailHub
            jobSlug={jobSlug}
            locale={locale}
            closeModal={() => {
              setOpenJobDetailModal(false);
              updateModalHistory({
                pathname: `${getI18nLinkHref(`/job/${jobSlug}`, locale)}`,
              });
            }}
          />
        )}
      />


      {(jobApplied && eventType !== 'application-viewed') ? (
        <Button
          styling="soft"
          color="primary-light"
          rounding="full"
          size="sm"
          className="flex-1 overflow-hidden"
          disabled
        >
          <span className="truncate">
            {t('job.apply.react.toast.success')}
          </span>
        </Button>
      ) : (
        <>{renderActionButton()}</>
      )}

    </div>
  );
});
