import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useFeedTracking } from '@hooks/tracking/useFeedTracking';

export const useFeedCardIsInViewport = ({
  itemPosition,
  requestId,
  mountTime,
  slug,
  eventType,
  eventId,
}: {
  itemPosition : number,
  eventType: string,
  eventId: number,
  requestId: string,
  mountTime : number,
  slug: string,
}) => {
  const { trackEvent } = useFeedTracking();

  const { ref, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true,
    trackVisibility: true,
    delay: 1000,
  });

  useEffect(() => {
    if (inView) {
      const currentTime = Date.now();
      const durationInSeconds = (currentTime - mountTime);

      trackEvent({
        position: itemPosition,
        event_id: eventId,
        request_id: requestId,
        event_type: eventType,
        interaction: 'seen',
        created_at: new Date().toISOString(),
        duration: durationInSeconds,
        context: 'Context',
        userSlug: slug,
      });
    }
  }, [inView, trackEvent, itemPosition, eventId, requestId, eventType, mountTime, slug]);

  return { ref };
};
