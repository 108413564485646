import { useTranslation } from 'next-i18next';
import { Button, InfiniteList } from '@components/common';
import { ApiBjFeed } from '@type/v1-api-types/bj-feed';
import { ExtendedSWRInfiniteResponse } from '@type/swr';
import { useAppSelector } from '@store/index';
import { dataTestId } from '@services/data-test-id';
import { EventsFeedCardMemo } from './EventsFeedCard';

// Interfaces
// ******************************************
interface PickedSwrHookProps extends Pick<ExtendedSWRInfiniteResponse<ApiBjFeed, unknown>, | 'data' | 'size' | 'setSize' | 'isEmpty' | 'isLoading' | 'showLoadMore' | 'sizeAsRef'> {}
interface EventsFeedListProps extends PickedSwrHookProps {
  locale: Locale,
  autoload?: number,
}


/**
 * @description List component for displaying events. It uses the EventsFeedCardMemo component to render the cards.
 * The interface for 'data' is ApiBjFeed.
 *
 * - **data**: ApiBjFeed[] - is the data fetched from the server with SwrInfinite
 * - **size**: number - is the size of the infinite list (get it from the SwrInfinite hook)
 * - **setSize**: Dispatch<SetStateAction<number>> - is the function to set the size of the infinite list (get it from the SwrInfinite hook)
 * - **isEmpty**: boolean - is the flag to show the empty message (get it from the SwrInfinite hook)
 * - **isLoading**: boolean - is the flag to show the loading spinner (get it from the SwrInfinite hook)
 * - **showLoadMore**: boolean - is the flag to show the load more button (get it from the SwrInfinite hook)
 * - **sizeAsRef**: MutableRefObject<number> - is the ref for the size of the infinite list (get it from the SwrInfinite hook)
 * - **autoload**: number - is the number of pages to autoload. Default is 3.
 * - **locale**: Locale - The current app locale.
 */
export const EventsFeedList = (props: EventsFeedListProps) => {
  // Destructure props
  const {
    data,
    size,
    setSize,
    isEmpty,
    isLoading,
    showLoadMore,
    sizeAsRef,
    autoload = 3,
    locale,
  } = props;

  // Translation
  const { t } = useTranslation('common');
  const headerInfo = useAppSelector((state) => state.user.headerInfo);
  const isCompany = headerInfo && headerInfo.role !== 'USER';

  return (
    <InfiniteList
      autoload={autoload}
      infiniteProps={{
        setSize,
        isEmpty,
        isLoading,
        showLoadMore,
        sizeAsRef,
      }}
      isEmptyLabels={{
        isEmptyTitle: 'no_results',
        isEmptyText: 'cv.search.message.no_result',
      }}
      renderList={() => (
        <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:my-6">
          {/* Render the events feed cards */
              data?.map((pageGroup, pageIndex) => pageGroup?.items?.map((item, index) => {
                const previousItemsCount = data
                  .slice(0, pageIndex)
                  .reduce((acc, group) => acc + group.items.length, 0);

                const globalPosition = previousItemsCount + index;

                const eventDataObj = Object.entries(item.eventData)[0][1];
                const keyHelper = item.eventType === 'company-hire'
                  ? eventDataObj.jobTitle
                  : eventDataObj.candidateName;

                return (
                  <EventsFeedCardMemo
                    key={`${item.createdAt}-${item.eventType}-${keyHelper}`}
                    event={item}
                    requestId={pageGroup?.requestId ?? ''}
                    isCompany={isCompany ?? false}
                    locale={locale}
                    itemPosition={globalPosition}
                  />
                );
              }))
          }
        </div>
      )}
      renderButton={() => (
        <div className="my-8 text-center">
          <Button
            isLoading={isLoading}
            rounding="full"
            color="secondary"
            onClick={() => { void setSize(size + 1); }}
            {...dataTestId('eventsFeedList.loadMoreButton')}
          >
            {t('dashboard.label.notification_show')}
          </Button>
        </div>
      )}
    />
  );
};
