// BJ FEED   **************************************************************************************
// pathname: /v1/bj-feed
// ************************************************************************************************
import {
  useSwrInfiniteApi,
  ApiBjFeed,
  ApiBjFeedServer,
} from '@hooks/useSwrApi';


// Interface
// ********************************************
interface BjFeedSwrProps {
  locale?: string;
  serverSideData?: ApiBjFeedServer;
  startIndex?: number;
  limit?: number;
  urlParams?: string;
}


/**
 * @description SWR: fetch bj-feed with infinite pagination.
 *
 * * **locale**: string. What locale to use for the request.
 * * **serverSideData**: ApiBjFeedServer, optional. The data we got from the server side request (if we do it). Default undefined.
 * * **startIndex**: number, optional. The first page number to fetch (ie: fetch starting with page 5). Default 1.
 * * **limit**: number, optional. The number of items to get per call. Modifies the *limit* url parameter (&limit=24). Default 24.
 * * **restUrlParams**: '&sort=relevant', optional. Default empty string.
 */
export const useSwrBjFeed = (props: BjFeedSwrProps) => {
  // Props destructuring
  // ********************************************
  const {
    locale,
    serverSideData,
    startIndex = 1,
    limit = 24,
    urlParams = '',
  } = props;


  // Get data from API
  // ********************************************
  const {
    data,
    error,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    mutatePage,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  } = useSwrInfiniteApi<ApiBjFeed>({
    url: '/v2/bj-feed',
    locale,
    urlParams,
    serverSideData,
    infiniteConfig: {
      paginationType: 'page',
      limit,
      // the key where the array of items is located in the response
      responseArrayKey: 'items',
      startIndex,
    },
    apiOptions: {
      keepPreviousData: true,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 5, // 5 minutes
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    mutatePage,
    isEmpty,
    // This endpoint might have an uneven number of items, so we need to check if we have more pages to load by
    // actually checking the last page number. That's why we're changing the && to ||.
    showLoadMore: showLoadMore || data?.at(-1)?.page !== data?.at(-1)?.pages,
    sizeAsRef,
  };
};
